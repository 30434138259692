
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as demoyiGmbSjZLEUM6H7IiyjD6eVRxKS_45H0nLl4FoDVYqJQ8Meta } from "/mcc/src/pages/demo.vue?macro=true";
import { default as faqsK67ask557zZtf5K6_45hWtiTFn7EPDs5eC9MQobJOES9UMeta } from "/mcc/src/pages/faqs.vue?macro=true";
import { default as indexXFI46OsJb_45tlcCB3uX1kzUBeCuHld8IfkJHZyxJwwzAMeta } from "/mcc/src/pages/index.vue?macro=true";
import { default as login0hJCVfOfGzyR4DVCAvpJ0MiZBAeJ7lTQ3l3FpIoa5S8Meta } from "/mcc/src/pages/login.vue?macro=true";
import { default as calendarsVhQ_rtThCLoqrl1IuBrP51oMWwdLlxCPu_87iAJdssMeta } from "/mcc/src/pages/calendar.vue?macro=true";
import { default as newCsPY4Gj0fiAiH9KUZsnP_NhGxaxZmlTVjFtU3B_keRcMeta } from "/mcc/src/pages/news/new.vue?macro=true";
import { default as registerieSJZE76atVs_45c13S6jl9_45QjkU_7NNL0vImkkZE1K5sMeta } from "/mcc/src/pages/register.vue?macro=true";
import { default as _91_46_46_46slug_93xSyoQS26fJ_kuHvyxMZrWPorriGOpba4W5QTcWLXspIMeta } from "/mcc/src/pages/[...slug].vue?macro=true";
import { default as contact_45us8dxnSujlIRgTALN_R2U1LTIpMbQCqiL7RC9NFLFMtOAMeta } from "/mcc/src/pages/contact-us.vue?macro=true";
import { default as demo_45eventagubRve7TnKU86Y82n9IDNNxeCsl91gAunj3Ay9GcywMeta } from "/mcc/src/pages/demo-event.vue?macro=true";
import { default as newHUMBfuIoIF9ENVwD5omf_tv_45hTSczz18uVuUzW4UulMMeta } from "/mcc/src/pages/events/new.vue?macro=true";
import { default as indexNexOA96tDnZwA4L1AIrXU9mSex06zbrJ_45hVtfKOKt2EMeta } from "/mcc/src/pages/news/index.vue?macro=true";
import { default as usersXrZlfLAEYxmacAyt8LyjR4_upV09kgX44m21lz8s6foMeta } from "/mcc/src/pages/admin/users.vue?macro=true";
import { default as new72L1Y21LuuE1bLjhmsHtjwLCABwamM4hzfcoyq8tkCQMeta } from "/mcc/src/pages/minutes/new.vue?macro=true";
import { default as our_45coaches4p3ZSDJZze4BJJO0VUP4cJnT3um7mJO1EAX0GPMAUT4Meta } from "/mcc/src/pages/our-coaches.vue?macro=true";
import { default as emailsvbAcX1odNf7WICre3nmkSP6rSeklNaRHHTXlTVuk0S8Meta } from "/mcc/src/pages/admin/emails.vue?macro=true";
import { default as sendIQ5UKk663YHt6xeFJky_9MeApqkvMtLeoo9uiNASkNIMeta } from "/mcc/src/pages/invites/send.vue?macro=true";
import { default as member_45check0rNllQ590gh4UxXV8wGPqe4__45_vpg1A8Dn4poREhS_45EMeta } from "/mcc/src/pages/member-check.vue?macro=true";
import { default as confirm_45emailjHgHpUyNs5JPYeEikoRFdluA9uCISvloJz_455ndOi5DsMeta } from "/mcc/src/pages/confirm-email.vue?macro=true";
import { default as indexoFNClzjILvcedpxTAtJlF3ST0fU1RlJa6M3f0zKw_AwMeta } from "/mcc/src/pages/minutes/index.vue?macro=true";
import { default as our_45committeeNelfs6Ye6pye_45fYuU2V34xlAvjADHc2765KcHVqOEZgMeta } from "/mcc/src/pages/our-committee.vue?macro=true";
import { default as consentZOl4W5K1_ww3hprB4FU5lAvPUJt1gPer8mlBwMuDctsMeta } from "/mcc/src/pages/events/consent.vue?macro=true";
import { default as acceptRHYVWQp4wf18FfDrTV8rjGXx3xZBBnyiPB1O7aB5ISYMeta } from "/mcc/src/pages/invites/accept.vue?macro=true";
import { default as createk1yFT_45nRo9OHUmR6gFHcpTXvHkWZYT1EKXIu3e7FIyEMeta } from "/mcc/src/pages/invites/create.vue?macro=true";
import { default as editRBy1kNxFyJm0A8g2lyRRDQt_45cTPihf2nLuZOBslHJxUMeta } from "/mcc/src/pages/news/[id]/edit.vue?macro=true";
import { default as reset_45passwordc9omyl2ju2bJfgLyq9unxBqQZG3qkYgsxbCst5A2ClEMeta } from "/mcc/src/pages/reset-password.vue?macro=true";
import { default as coached_45paddlesOv1iRLYWfAd5FNCo9yrsMVnNMbxVNEc5XCniq_45Mvk_458Meta } from "/mcc/src/pages/coached-paddles.vue?macro=true";
import { default as forgot_45passwordCjUgSRLwqM4ffZnip5H0zEmepKPECDktsYUdVGQbJD0Meta } from "/mcc/src/pages/forgot-password.vue?macro=true";
import { default as newGWJb2w2dbyAp0fuSk9QF_j0lPcB3t1af_45ZGrtFR4muIMeta } from "/mcc/src/pages/newsletters/new.vue?macro=true";
import { default as old_45newslettersWmo1WHqK8TREcqzyFIXmTt1TGOE8ggJhW4PLHvyNV_45UMeta } from "/mcc/src/pages/old-newsletters.vue?macro=true";
import { default as cancelc5LeYjvCVnB6VGfAahjanH1ApSlvslrSuFny73gUODsMeta } from "/mcc/src/pages/payments/cancel.vue?macro=true";
import { default as unsubscribeyprD_45CxIE2ATh3JHDmoi6CICSGBqgV1F1cEn270T8SoMeta } from "/mcc/src/pages/news/unsubscribe.vue?macro=true";
import { default as successc8tngsCj9sL94_45D5bu7U83LlH3z8anlJl_st2R_456DR0Meta } from "/mcc/src/pages/payments/success.vue?macro=true";
import { default as indexSes6O9T_453wConWvQbTdiRAdUDX52awt93kb7xGtVF68Meta } from "/mcc/src/pages/newsletters/index.vue?macro=true";
import { default as _91_46_46_46slug_93CXYsf9vPuprMIpeYAeuAnriU2dI9zIWAb6kB0QhDMf8Meta } from "/mcc/src/pages/profile/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93yaETaOVCV_A_9sGg6Rm5AGJIDvijKQL1akTqKAMWkCcMeta } from "/mcc/src/pages/news/[id]/[...slug].vue?macro=true";
import { default as beginner_45course_45dateswHxIctxKTptgPaqciWralpEywE34kYdaUaK2DbP2RkoMeta } from "/mcc/src/pages/beginner-course-dates.vue?macro=true";
import { default as mailing_45listX5SbkeQjJY_Zv7nz_hf_KvldXDFEtTOtp2xsc_45sVoJAMeta } from "/mcc/src/pages/unsubscribe/mailing-list.vue?macro=true";
import { default as editH5gBlwLElhnQKUAgcBpc3gLvyPI6WcaX_45Od4YOcmrmAMeta } from "/mcc/src/pages/events/[id]/[...slug]/edit.vue?macro=true";
import { default as index03wJIAqnzZtXoOyabeBWN9GG_45T17l3tgmX5RTm6MgT0Meta } from "/mcc/src/pages/events/[id]/[...slug]/index.vue?macro=true";
export default [
  {
    name: "demo",
    path: "/demo",
    meta: demoyiGmbSjZLEUM6H7IiyjD6eVRxKS_45H0nLl4FoDVYqJQ8Meta || {},
    component: () => import("/mcc/src/pages/demo.vue")
  },
  {
    name: "faqs",
    path: "/faqs",
    component: () => import("/mcc/src/pages/faqs.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexXFI46OsJb_45tlcCB3uX1kzUBeCuHld8IfkJHZyxJwwzAMeta || {},
    component: () => import("/mcc/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login0hJCVfOfGzyR4DVCAvpJ0MiZBAeJ7lTQ3l3FpIoa5S8Meta || {},
    component: () => import("/mcc/src/pages/login.vue")
  },
  {
    name: "calendar",
    path: "/calendar",
    component: () => import("/mcc/src/pages/calendar.vue")
  },
  {
    name: "news-new",
    path: "/news/new",
    meta: newCsPY4Gj0fiAiH9KUZsnP_NhGxaxZmlTVjFtU3B_keRcMeta || {},
    component: () => import("/mcc/src/pages/news/new.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerieSJZE76atVs_45c13S6jl9_45QjkU_7NNL0vImkkZE1K5sMeta || {},
    component: () => import("/mcc/src/pages/register.vue")
  },
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/mcc/src/pages/[...slug].vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/mcc/src/pages/contact-us.vue")
  },
  {
    name: "demo-event",
    path: "/demo-event",
    component: () => import("/mcc/src/pages/demo-event.vue")
  },
  {
    name: "events-new",
    path: "/events/new",
    meta: newHUMBfuIoIF9ENVwD5omf_tv_45hTSczz18uVuUzW4UulMMeta || {},
    component: () => import("/mcc/src/pages/events/new.vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/mcc/src/pages/news/index.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    meta: usersXrZlfLAEYxmacAyt8LyjR4_upV09kgX44m21lz8s6foMeta || {},
    component: () => import("/mcc/src/pages/admin/users.vue")
  },
  {
    name: "minutes-new",
    path: "/minutes/new",
    component: () => import("/mcc/src/pages/minutes/new.vue")
  },
  {
    name: "our-coaches",
    path: "/our-coaches",
    component: () => import("/mcc/src/pages/our-coaches.vue")
  },
  {
    name: "admin-emails",
    path: "/admin/emails",
    meta: emailsvbAcX1odNf7WICre3nmkSP6rSeklNaRHHTXlTVuk0S8Meta || {},
    component: () => import("/mcc/src/pages/admin/emails.vue")
  },
  {
    name: "invites-send",
    path: "/invites/send",
    meta: sendIQ5UKk663YHt6xeFJky_9MeApqkvMtLeoo9uiNASkNIMeta || {},
    component: () => import("/mcc/src/pages/invites/send.vue")
  },
  {
    name: "member-check",
    path: "/member-check",
    meta: member_45check0rNllQ590gh4UxXV8wGPqe4__45_vpg1A8Dn4poREhS_45EMeta || {},
    component: () => import("/mcc/src/pages/member-check.vue")
  },
  {
    name: "confirm-email",
    path: "/confirm-email",
    component: () => import("/mcc/src/pages/confirm-email.vue")
  },
  {
    name: "minutes",
    path: "/minutes",
    component: () => import("/mcc/src/pages/minutes/index.vue")
  },
  {
    name: "our-committee",
    path: "/our-committee",
    component: () => import("/mcc/src/pages/our-committee.vue")
  },
  {
    name: "events-consent",
    path: "/events/consent",
    meta: consentZOl4W5K1_ww3hprB4FU5lAvPUJt1gPer8mlBwMuDctsMeta || {},
    component: () => import("/mcc/src/pages/events/consent.vue")
  },
  {
    name: "invites-accept",
    path: "/invites/accept",
    component: () => import("/mcc/src/pages/invites/accept.vue")
  },
  {
    name: "invites-create",
    path: "/invites/create",
    meta: createk1yFT_45nRo9OHUmR6gFHcpTXvHkWZYT1EKXIu3e7FIyEMeta || {},
    component: () => import("/mcc/src/pages/invites/create.vue")
  },
  {
    name: "news-id-edit",
    path: "/news/:id()/edit",
    component: () => import("/mcc/src/pages/news/[id]/edit.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/mcc/src/pages/reset-password.vue")
  },
  {
    name: "coached-paddles",
    path: "/coached-paddles",
    component: () => import("/mcc/src/pages/coached-paddles.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/mcc/src/pages/forgot-password.vue")
  },
  {
    name: "newsletters-new",
    path: "/newsletters/new",
    component: () => import("/mcc/src/pages/newsletters/new.vue")
  },
  {
    name: "old-newsletters",
    path: "/old-newsletters",
    meta: old_45newslettersWmo1WHqK8TREcqzyFIXmTt1TGOE8ggJhW4PLHvyNV_45UMeta || {},
    component: () => import("/mcc/src/pages/old-newsletters.vue")
  },
  {
    name: "payments-cancel",
    path: "/payments/cancel",
    component: () => import("/mcc/src/pages/payments/cancel.vue")
  },
  {
    name: "news-unsubscribe",
    path: "/news/unsubscribe",
    component: () => import("/mcc/src/pages/news/unsubscribe.vue")
  },
  {
    name: "payments-success",
    path: "/payments/success",
    component: () => import("/mcc/src/pages/payments/success.vue")
  },
  {
    name: "newsletters",
    path: "/newsletters",
    component: () => import("/mcc/src/pages/newsletters/index.vue")
  },
  {
    name: "profile-slug",
    path: "/profile/:slug(.*)*",
    meta: _91_46_46_46slug_93CXYsf9vPuprMIpeYAeuAnriU2dI9zIWAb6kB0QhDMf8Meta || {},
    component: () => import("/mcc/src/pages/profile/[...slug].vue")
  },
  {
    name: "news-id-slug",
    path: "/news/:id()/:slug(.*)*",
    component: () => import("/mcc/src/pages/news/[id]/[...slug].vue")
  },
  {
    name: "beginner-course-dates",
    path: "/beginner-course-dates",
    component: () => import("/mcc/src/pages/beginner-course-dates.vue")
  },
  {
    name: "unsubscribe-mailing-list",
    path: "/unsubscribe/mailing-list",
    meta: mailing_45listX5SbkeQjJY_Zv7nz_hf_KvldXDFEtTOtp2xsc_45sVoJAMeta || {},
    component: () => import("/mcc/src/pages/unsubscribe/mailing-list.vue")
  },
  {
    name: "events-id-slug-edit",
    path: "/events/:id()/:slug([^/]*)*/edit",
    meta: editH5gBlwLElhnQKUAgcBpc3gLvyPI6WcaX_45Od4YOcmrmAMeta || {},
    component: () => import("/mcc/src/pages/events/[id]/[...slug]/edit.vue")
  },
  {
    name: "events-id-slug",
    path: "/events/:id()/:slug(.*)*",
    component: () => import("/mcc/src/pages/events/[id]/[...slug]/index.vue")
  }
]